export const phoneNumberToE164 = (phoneNumber = ''): string => {
  // Replace all special characters except + and numbers
  const number = phoneNumber.replace(/[^+0-9]/g, '').trim()
  if (number.startsWith('+')) {
    return number
  }
  if (number.startsWith('1')) {
    return '+' + number
  }
  // Assumes all users have a US phone number
  return '+1' + number
}
