import { createTheme } from '@mui/material/styles'

const PRIMARY_COLOR = '#0693B2'
const ERROR_COLOR = '#D5545B'
const TEXT_PRIMARY_COLOR = '#07282F'
const DIVIDER_COLOR = '#E4E6EE'
const TEXT_SECONDARY_COLOR = `${TEXT_PRIMARY_COLOR}80`

const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR
    },
    error: {
      main: ERROR_COLOR
    },
    text: {
      primary: TEXT_PRIMARY_COLOR,
      secondary: TEXT_SECONDARY_COLOR
    },
    background: {
      default: '#F9FAFF',
      paper: '#FFFFFF'
    },
    action: {
      hover: '#0693B236'
    },
    divider: DIVIDER_COLOR
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: `1px solid ${DIVIDER_COLOR}`,
          '&:first-of-type': {
            borderRadius: 0
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': {
            background: 'none'
          }
        },
        expandIconWrapper: {
          color: 'inherit'
        }
      }
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          fontSize: '1rem',
          textTransform: 'none',
          borderRadius: '500px'
        },
        text: {
          textTransform: 'none'
        },
        outlined: {
          borderWidth: 2,
          '&:hover': {
            backgroundColor: 'transparent',
            borderWidth: 2
          }
        },
        outlinedPrimary: {
          color: TEXT_PRIMARY_COLOR,
          borderColor: TEXT_PRIMARY_COLOR,
          '&:hover': {
            borderColor: TEXT_PRIMARY_COLOR,
            backgroundColor: `${TEXT_PRIMARY_COLOR}05`
          }
        },
        containedPrimary: {
          color: 'white',
          backgroundImage:
            'linear-gradient(94.54deg, #029F9B 12.07%, #0C7D96 53.97%, #335591 98.87%)',
          ':hover': {
            filter: 'saturate(110%) brightness(110%)'
          },
          '&.Mui-disabled': {
            backgroundImage: 'none'
          },
          '&.MuiButton-root > span > svg': {
            color: 'white'
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '4px'
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: TEXT_PRIMARY_COLOR,
          '&.Mui-disabled > .MuiSvgIcon-root': {
            color: TEXT_SECONDARY_COLOR
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '> .MuiOutlinedInput-notchedOutline': {
            borderColor: DIVIDER_COLOR,
            borderRadius: 1
          }
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: 'inherit'
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: 'white',
            backgroundColor: PRIMARY_COLOR,
            fontWeight: '500'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          boxShadow: '0px 2px 12px 2px rgba(21, 151, 182, 0.05)'
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          border: `1px solid ${DIVIDER_COLOR}`
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${DIVIDER_COLOR}`
        }
      }
    },
    MuiCollapse: {
      styleOverrides: {
        wrapperInner: {
          '& .SnackbarItem-variantSuccess': {
            backgroundColor: '#43a047'
          },
          '& .SnackbarItem-variantError': {
            backgroundColor: ERROR_COLOR
          },
          '& .SnackbarItem-variantWarning': {
            backgroundColor: '#ff9800'
          },
          '& .SnackbarItem-variantInfo': {
            backgroundColor: '#2196f3'
          },
          '& .SnackbarItem-message': {
            fontWeight: '500'
          }
        }
      }
    }
  },
  typography: {
    fontFamily: ['Inter', '"Inter Fallback"', 'sans-serif'].join(',')
  }
})

export default theme
