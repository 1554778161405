import { useState } from 'react'
import { Auth, CognitoUser } from '@aws-amplify/auth'
import { useRouter } from 'next/router'
import {
  Box,
  Button,
  Container,
  TextField,
  FormControl,
  Card,
  CardContent
} from '@mui/material'
import { useUserSessionContext } from 'contexts/UserSession/UserSessionContext'
import qs from 'qs'
import { phoneNumberToE164 } from 'apps/Authentication/helpers/authHelpers'
import { isNestedWebView } from 'utils/webview'
import useSnackbar from 'hooks/useSnackbar'

const LoginPage = (): JSX.Element => {
  const { onLoginSuccess } = useUserSessionContext()
  const router = useRouter()
  const [loginType, setLoginType] = useState<'phone' | 'password'>('password')
  const [submitting, setSubmitting] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | undefined>(
    undefined
  )
  const [phoneLoginStep, setPhoneLoginStep] = useState<
    'phoneNumber' | 'verificationCode'
  >('phoneNumber')
  const [verificationCode, setVerificationCode] = useState('')
  const { enqueueSnackbar } = useSnackbar()

  const onLogin = () => {
    onLoginSuccess()
    const { redirect_to } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    })
    // If the browser is in a WebView, then the user is using the Beluga Kitchen
    // app and should be redirected to the ROM at /portal. Otherwise, redirect
    // to the Ops app at /ops.
    const isWebview = isNestedWebView(window)
    if (typeof redirect_to === 'string') {
      router.replace(redirect_to)
    } else {
      router.replace(isWebview ? '/portal' : '/ops')
    }
  }

  const onSubmitPasswordLogin = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()
    try {
      setSubmitting(true)
      await Auth.signIn(username, password)
      onLogin()
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' })
      setSubmitting(false)
    }
  }

  const onSubmitPhoneNumber = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()
    try {
      setSubmitting(true)
      const cognitoUser = await Auth.signIn(phoneNumberToE164(phoneNumber))
      setCognitoUser(cognitoUser)
      setSubmitting(false)
      setPhoneLoginStep('verificationCode')
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' })
      setSubmitting(false)
    }
  }

  const onSubmitVerificationCode = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault()
    try {
      setSubmitting(true)
      const { signInUserSession } = await Auth.sendCustomChallengeAnswer(
        cognitoUser,
        verificationCode
      )
      if (signInUserSession) {
        onLogin()
      } else {
        setSubmitting(false)
        enqueueSnackbar('Verification code is invalid', { variant: 'error' })
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' })
      setSubmitting(false)
    }
  }

  if (loginType === 'phone') {
    return (
      <>
        <Container maxWidth="sm">
          <Box
            component="img"
            src="/icons/beluga-gradient-logo.svg"
            alt="Beluga"
            mx="auto"
            my={4}
          />
          <Card>
            {phoneLoginStep === 'phoneNumber' && (
              <form onSubmit={onSubmitPhoneNumber}>
                <CardContent>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      autoFocus
                      required
                      type="tel"
                      label="Phone Number"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={phoneNumber}
                      onChange={(event) => {
                        setPhoneNumber(event.target.value)
                      }}
                      placeholder="+19005556666"
                      inputProps={{
                        'aria-label': 'phone-input'
                      }}
                    />
                  </FormControl>
                  <Box mt={2} mx="auto" maxWidth="50%">
                    <Button
                      fullWidth
                      size="large"
                      variant="contained"
                      type="submit"
                      disabled={submitting}
                    >
                      {submitting ? 'Sending' : 'Send SMS'}
                    </Button>
                  </Box>
                </CardContent>
              </form>
            )}
            {phoneLoginStep === 'verificationCode' && (
              <form onSubmit={onSubmitVerificationCode}>
                <CardContent>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      autoFocus
                      required
                      type="text"
                      label="Verification Code"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={verificationCode}
                      onChange={(event) => {
                        setVerificationCode(event.target.value)
                      }}
                      inputProps={{
                        'aria-label': 'verification-code-input'
                      }}
                    />
                  </FormControl>
                  <Box mt={2} mx="auto" maxWidth="50%">
                    <Button
                      fullWidth
                      size="large"
                      variant="contained"
                      type="submit"
                      disabled={submitting}
                    >
                      {submitting ? 'Submitting' : 'Submit'}
                    </Button>
                  </Box>
                </CardContent>
              </form>
            )}
          </Card>
          <Button
            type="button"
            onClick={() => setLoginType('password')}
            sx={{ display: 'block', mt: 4, mx: 'auto' }}
          >
            Login with Username and Password
          </Button>
        </Container>
      </>
    )
  }

  return (
    <>
      <Container maxWidth="sm">
        <Box
          component="img"
          src="/icons/beluga-gradient-logo.svg"
          alt="Beluga"
          mx="auto"
          my={4}
        />
        <form onSubmit={onSubmitPasswordLogin}>
          <Card>
            <CardContent>
              <FormControl fullWidth margin="normal">
                <TextField
                  autoFocus
                  required
                  type="text"
                  label="Username"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={username}
                  onChange={(event) => {
                    setUsername(event.target.value)
                  }}
                  inputProps={{
                    'aria-label': 'username-input',
                    autoCapitalize: 'none',
                    autoCorrect: 'off'
                  }}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  required
                  type="password"
                  label="Password"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value)
                  }}
                  inputProps={{
                    'aria-label': 'password-input'
                  }}
                />
              </FormControl>
              <Box mt={2} mx="auto" maxWidth="50%">
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  type="submit"
                  disabled={submitting}
                >
                  {submitting ? 'Submitting' : 'Log in'}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </form>
        <Button
          type="button"
          onClick={() => setLoginType('phone')}
          sx={{ display: 'block', mt: 4, mx: 'auto' }}
        >
          Login with Phone Number
        </Button>
      </Container>
    </>
  )
}

export default LoginPage
