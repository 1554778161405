import LoginPage from 'apps/Authentication/LoginPage/LoginPage'
import AuthContainer from 'apps/Authentication/AuthApp/AuthContainer'

const Login = (): JSX.Element => {
  return <LoginPage />
}

Login.getLayout = (page: JSX.Element) => {
  return <AuthContainer>{page}</AuthContainer>
}

export default Login
