import { useMemo, useCallback } from 'react'
import {
  useSnackbar as useNotistack,
  SnackbarMessage,
  OptionsObject,
  SnackbarKey
} from 'notistack'
import merge from 'lodash/merge'
import { ApolloError } from '@apollo/client'

/**
 * A wrapper of useSnackbar from notistack which can display user friendly error
 * messages from Error objects and API responses.
 */
export default function useSnackbar(): {
  enqueueSnackbar: (
    snackbarMessage: unknown,
    options?: OptionsObject
  ) => SnackbarKey
  closeSnackbar: (key?: SnackbarKey) => void
} {
  const notistack = useNotistack()

  const enqueueSnackbar = useCallback(
    (errorOrMessage: unknown, options: OptionsObject = {}) => {
      const defaultOptions = {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 5000
      }

      let snackbarMessage: SnackbarMessage

      if (errorOrMessage instanceof ApolloError) {
        const graphQLError = errorOrMessage.graphQLErrors[0]
        const fields = graphQLError?.extensions?.fields
        if (typeof fields === 'object' && Object.entries(fields).length) {
          // Shows the first changeset error message from the API if present
          const field = Object.entries(fields)[0]
          const key = field[0]
          const msg = (field[1] as string[])[0]
          snackbarMessage = key + ' ' + msg
        } else if (graphQLError) {
          snackbarMessage = graphQLError.message
        } else {
          snackbarMessage = errorOrMessage.message
        }
      } else if (errorOrMessage instanceof Error) {
        snackbarMessage = errorOrMessage.message
      } else if (
        Object.prototype.hasOwnProperty.call(errorOrMessage, 'message')
      ) {
        snackbarMessage = (errorOrMessage as { message: string }).message
      } else if (typeof errorOrMessage === 'string') {
        snackbarMessage = errorOrMessage
      } else {
        snackbarMessage = JSON.stringify(errorOrMessage)
      }

      return notistack.enqueueSnackbar(
        snackbarMessage,
        merge(defaultOptions, options)
      )
    },
    [notistack]
  )

  const closeSnackbar = useCallback(
    (key?: SnackbarKey) => {
      return notistack.closeSnackbar(key)
    },
    [notistack]
  )

  const memoizedValue = useMemo(() => {
    return { enqueueSnackbar, closeSnackbar }
  }, [enqueueSnackbar, closeSnackbar])

  return memoizedValue
}
