import { AppProviders } from 'pages/_app'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import theme from 'apps/Ops/styles/opsTheme'
import { ApolloProvider } from '@apollo/client'
import client from 'apps/Authentication/apollo/client'
import UserSessionProvider from 'contexts/UserSession/UserSessionProvider'
import TrackingProvider from 'contexts/Tracking/TrackingProvider'
import { SnackbarProvider } from 'notistack'

export const AuthProviders = ({
  children
}: {
  children: JSX.Element
}): JSX.Element => {
  return (
    <AppProviders>
      <ThemeProvider theme={theme}>
        <SnackbarProvider hideIconVariant maxSnack={3}>
          <CssBaseline />
          <TrackingProvider>
            <UserSessionProvider>{children}</UserSessionProvider>
          </TrackingProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </AppProviders>
  )
}

const AuthContainer = ({
  children
}: {
  children: JSX.Element
}): JSX.Element => {
  return (
    <ApolloProvider client={client}>
      <AuthProviders>{children}</AuthProviders>
    </ApolloProvider>
  )
}

export default AuthContainer
